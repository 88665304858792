import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import createQueryClient from './lib/createQueryClient';

import shouldPolyfill from './polyfills/should';

const Panneau = React.lazy(() => import('./components/Panneau'));
const App = React.lazy(() => import('./components/Container'));

const withStrictMode = process.env.REACT_STRICT_MODE ?? false;

declare global {
    interface Window {
        props?: Record<string, any>;
    }
}

function getAppProps() {
    return window.props || {};
}

function renderPanneau(props) {
    createRoot(document.getElementById('app')).render(
        <Suspense fallback={null}>
            <Panneau {...props} />
        </Suspense>,
    );
}

function renderApp(props) {
    const { page = null, user } = props;
    const queryClient = createQueryClient();
    if (page !== null) {
        queryClient.setQueryData(['page', page.url], page);
    }
    if (typeof user !== 'undefined') {
        queryClient.setQueryData(['user'], user);
    }
    const app = (
        <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV === 'development' ? (
                <ReactQueryDevtools initialIsOpen={false} />
            ) : null}
            <Suspense fallback={null}>
                <App {...props} />
            </Suspense>
        </QueryClientProvider>
    );
    createRoot(document.getElementById('app')).render(
        withStrictMode ? <StrictMode>{app}</StrictMode> : app,
    );
}

(shouldPolyfill() ? import('./polyfills/index') : Promise.resolve()).then(() => {
    const { isPanneau = false, ...props } = getAppProps();
    return isPanneau ? renderPanneau(props) : renderApp(props);
});
