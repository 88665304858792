import { QueryClient, QueryObserverOptions } from '@tanstack/react-query';

export default function createQueryClient(opts?: QueryObserverOptions): QueryClient {
    return new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: 60 * 1000,
                ...opts,
            },
        },
    });
}
